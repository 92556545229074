@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700&display=swap');

html,
body {
    height: 100%;
    width: 100%;
    margin: 0px;
    background: #ffffff;
    font-family: Rubik;
}
